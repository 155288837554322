import React, { useState, useEffect } from "react";
import { filter } from 'lodash'
import { graphql } from 'gatsby'
import { Container } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";
import { getCategory, getItemLink } from "../utils/news";

import NewsImg_1 from "../images/journal_img_1.png";
import NewsImg_2 from "../images/journal_img_2.png";

const InnerIntroModule = loadable(() => import("../components/InnerIntroModule/InnerIntroModule"));
const NewsCard = loadable(() => import("../components/NewsCard/NewsCard"));
const StaticDetailPagination = loadable(() => import("../components/StaticDetailPagination/StaticDetailPagination"));

const RenderNewsItems = ({data}) => {
    return (
        <>
        {data.map((news) => {
            let categories = getCategory(news.categories)
            let image = NewsImg_2;
            if (news?.featuredImage?.node?.sourceUrl) {
                image = news.featuredImage.node.sourceUrl
            }
            return(
                <NewsCard 
                    key={news.id}
                    img={image}
                    status={categories}
                    text={news.title}
                    // slug={`/news/${news.slug}`}
                    slug={getItemLink(news)}
                />
            )
        })}
        </>
    )
}


const NewsLanding = (props) => {
    const newsItemList = props.data.allWpPost.nodes
    const [filter, setFilter] = useState('All');
    const [newsItems, setNewsItems] = useState(newsItemList || [])

    useEffect(() => {
        if (filter) {
            let filterData = []
            if (filter !== 'All') {
                filterData = newsItemList.filter((n) => {
                    const categoryList = getCategory(n.categories)
                    return (categoryList).includes(filter)
                })
            } else {
                filterData = newsItemList
            }
            setNewsItems(filterData)
        }
    },[filter])
    const collectCategories = () => {
        let categoryList = []
        newsItems.map((n) => {
            n.categories.nodes.map((c) => {
                if (!categoryList.includes(c.name))
                    categoryList.push(c.name)
            })
        })
        return categoryList;
    }

    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <div className="inner-wrapper">
                <Container>
                    <InnerIntroModule
                        title={`News`}
                        list={collectCategories()}
                        filter={filter}
                        setFilter={setFilter}
                    />

                    <div className="inner-wrapper-card-main">
                        <StaticDetailPagination
                            component={RenderNewsItems}
                            // reloadDataFun={reloadDataFun}
                            data={newsItems}
                        />
                    </div>
                </Container>
            </div>

        </Layout>
    )
}

export const query = graphql`
  query {
    allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: "property-news-and-insights"}}}}, status: {eq: "publish"}}, sort: {order: DESC, fields: date}) {
      nodes {
        uri
        title
        status
        slug
        categories {
          nodes {
            name
            slug
            parentId
          }
        }
        content
        featuredImage {
            node {
              id
              sourceUrl
            }
        }
      }
    }
  }
`

export default NewsLanding